'use client';

import React from 'react';
import ReactDOM from 'react-dom';

import { styled } from '@mui/material/styles';

import sidekick from '@last-rev/contentful-sidekick-util';
// import Background from '../Background';
import Grid from '../Grid';
import ContentModule from '../ContentModule';

import type { FormProps, FormOwnerState } from './Form.types';
import ErrorBoundary from '../ErrorBoundary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Script from 'next/script';
import { useRouter } from 'next/navigation';
import Background from '../Background';
import dynamic from 'next/dynamic';

const ReCAPTCHA = dynamic(() => import('react-google-recaptcha'));

declare global {
  interface Window {
    MktoForms2: any; // Replace 'any' with the actual type if available
  }
}

const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

export const invalidPersonalDomains = [
  '@gmail.',
  '@yahoo.',
  '@hotmail.',
  '@live.',
  '@aol.',
  '@outlook.',
  '@vomo.',
  '@godaddy.',
  '@comcast.',
  '@verizon.',
  '@zoho.',
  '@msn.',
  '@icloud.'
];

const Form = (props: FormProps) => {
  const formRef = React.useRef(null);
  const router = useRouter();
  const ownerState = { ...props };
  const [loaded, setLoaded] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const token = React.useRef(null);

  const {
    id,
    introText,
    sidekickLookup,
    marketoFormId,
    confirmationPath,
    allowPersonalEmailAddresses,
    hideRecaptcha,
    successMessage,
    rteDisclaimerText: disclaimerText,
    // background,
    backgroundColor
  } = props;
  const [form, setForm] = React.useState<any>(null);
  const handleCaptchaChange = (aToken: string | null) => {
    token.current = aToken;
  };

  const validateRecaptcha = (aForm: any) => {
    if (!token.current && aForm) {
      aForm.submittable(false);
      aForm.showErrorMessage('Must complete recaptcha.');
      return false;
    }
    return true;
  };

  const handleLoad = React.useCallback(() => {
    try {
      // let loaded: any = [];
      // if (loaded[marketoFormId]) return;
      // loaded[marketoFormId] = true;

      const formEls = [].slice.call(
        document.querySelectorAll('[data-formid="' + marketoFormId + '"]:not(.mktoForm)')
      );
      // console.log('HandleLoad', `marketo-forms-script_${marketoFormId}_${id}`);
      // console.log(
      //   'HandleLoad',
      //   formEls
      (function formLoader(formEls: any) {
        const el = formEls.shift();
        // const el = formRef.current;
        if (!el || !window.MktoForms2) return;
        el.id = `mktoForm_${marketoFormId}`;
        // console.log('loadForm', el.id);
        // console.time('loadForm' + el.id);
        const timer = new Date();
        window.MktoForms2.loadForm(
          `//${process.env.NEXT_PUBLIC_MARKETO_BASE_URL}`,
          process.env.NEXT_PUBLIC_MUNCHKIN_ID,
          marketoFormId,
          (form: any) => {
            if (!form) {
              console.log('Error loading form', marketoFormId);
              return false;
            }

            el.id = '';
            setForm(form);
            form.setValues({
              Last_Marketo_Form_URL__c: document.location.href
            });

            const emailElement = form.getFormElem().find('#Email');
            //
            form.onSuccess((data: any, followUpUrl: string) => {
              // console.log('onSuccess', {
              //   data,
              //   confirmationPath,
              //   successMessage,
              //   followUpUrl
              // });
              setSuccess(true);
              if (confirmationPath) {
                router.push(confirmationPath);
                return false;
              }
              if (successMessage) {
                return false;
              }
              if (followUpUrl) {
                router.push(followUpUrl);
                return false;
              }
              // Return false to prevent the submission handler continuing with its own processing
            });

            const isEmailGood = (email: string) => {
              for (let i = 0; i < invalidPersonalDomains.length; i++) {
                let domain = invalidPersonalDomains[i];
                if (email.indexOf(domain) !== -1) {
                  return false;
                }
              }
              return true;
            };
            const showEmailError = (email: string) => {
              const isValidEmail = isEmailGood(email);

              if (isValidEmail) {
                return form.submittable(false);
              }

              form.submittable(false);
              form.showErrorMessage('Must be Business email.', emailElement);
            };
            const validateEmail = () => {
              const enteredEmail = form.vals().Email;

              if (enteredEmail) {
                showEmailError(enteredEmail);
              }
              return true;
            };

            const validateForm = () => {
              if (!validateEmail() || !validateRecaptcha(form)) {
                return form.submittable(false);
              }
              return form.submittable(true);
            };

            if (!allowPersonalEmailAddresses) {
              emailElement.on({
                keyup: validateEmail,
                focus: validateEmail,
                blur: validateEmail
              });
            }
            form.onValidate(validateForm);
            if (formEls?.length) formLoader(formEls);
          }
        );
      })(formEls);
    } catch (error) {
      console.log('ErrorLoadingForm', error);
    }
  }, [
    allowPersonalEmailAddresses,
    confirmationPath,
    marketoFormId,
    router,
    successMessage,
    validateRecaptcha
  ]);
  // React.useEffect(() => {
  //   if (loaded) handleLoad();
  // }, [loaded]);
  React.useEffect(() => setLoaded(true), []);
  if (!props.marketoFormId) {
    return null;
  }
  return (
    <ErrorBoundary>
      <Root data-testid="Form" {...sidekick(sidekickLookup)} ownerState={ownerState}>
        <FormBackground
          // background={background}
          backgroundColor={backgroundColor}
        />

        <ContentOuterGrid ownerState={ownerState}>
          <MainContentWrap ownerState={ownerState}>
            <Script
              strategy="lazyOnload"
              key={`marketo-forms-script_${marketoFormId}`}
              id={`marketo-forms-script_${marketoFormId}`}
              src={`//${process.env.NEXT_PUBLIC_MARKETO_BASE_URL}/js/forms2/js/forms2.min.js`}
              onReady={handleLoad}
            />
            {success && successMessage ? (
              <FormWrap ownerState={ownerState}>
                <SuccessMessage ownerState={ownerState} {...successMessage} />
                {disclaimerText ? (
                  <FormDisclaimer
                    {...sidekick(sidekickLookup, 'disclaimerText')}
                    body={disclaimerText}
                    __typename="RichText"
                    data-testid="Form-disclaimerText"
                    ownerState={ownerState}
                  />
                ) : null}
              </FormWrap>
            ) : (
              <>
                {!!introText && (
                  <IntroText
                    ownerState={ownerState}
                    {...sidekick(sidekickLookup, 'introText')}
                    {...introText}
                    textVariant="introText"
                  />
                )}
                <FormWrap ownerState={ownerState}>
                  <form data-formid={`${marketoFormId}`} ref={formRef} className={`${id}`}>
                    {!hideRecaptcha && (
                      <RecaptchaWrap ownerState={ownerState}>
                        <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={handleCaptchaChange} />
                      </RecaptchaWrap>
                    )}
                  </form>
                  {disclaimerText ? (
                    <FormDisclaimer
                      {...sidekick(sidekickLookup, 'disclaimerText')}
                      body={disclaimerText}
                      __typename="RichText"
                      data-testid="Form-disclaimerText"
                      ownerState={ownerState}
                    />
                  ) : null}
                </FormWrap>
              </>
            )}
          </MainContentWrap>
        </ContentOuterGrid>
      </Root>
    </ErrorBoundary>
  );
};

const Root = styled(Box, {
  name: 'Form',
  slot: 'Root',
  overridesResolver: (_, styles) => [styles.root]
})<{ ownerState: FormOwnerState }>``;

const FormBackground = styled(Background, {
  name: 'Form',
  slot: 'Background',
  overridesResolver: (_, styles) => [styles.background]
})<{}>``;

const RecaptchaWrap = styled(Box, {
  name: 'Form',
  slot: 'RecaptchaWrap',
  overridesResolver: (_, styles) => [styles.recaptchaWrap]
})<{ ownerState: FormOwnerState }>``;

const FormWrap = styled(Box, {
  name: 'Form',
  slot: 'FormWrap',
  overridesResolver: (_, styles) => [styles.formWrap]
})<{ ownerState: FormOwnerState }>``;

const SuccessMessage = styled(ContentModule, {
  name: 'Form',
  slot: 'SuccessMessage',
  overridesResolver: (_, styles) => [styles.successMessage]
})<{ ownerState: FormOwnerState }>``;
const FormDisclaimer = styled(ContentModule, {
  name: 'Form',
  slot: 'FormDisclaimer',
  overridesResolver: (_, styles) => [styles.formDisclaimer]
})<{ ownerState: FormOwnerState }>``;

const ContentOuterGrid = styled(Grid, {
  name: 'Form',
  slot: 'ContentOuterGrid',
  overridesResolver: (_, styles) => [styles.contentOuterGrid]
})<{ ownerState: FormOwnerState }>``;

const IntroTextGrid = styled(Grid, {
  name: 'Form',
  slot: 'IntroTextGrid',
  overridesResolver: (_, styles) => [styles.introTextGrid]
})<{ ownerState: FormOwnerState }>``;

const IntroText = styled(ContentModule, {
  name: 'Form',
  slot: 'IntroText',
  overridesResolver: (_, styles) => [styles.introText]
})<{ ownerState: FormOwnerState }>``;

const MainContentWrap = styled('div', {
  name: 'Form',
  slot: 'MainContentWrap',
  overridesResolver: (_, styles) => [styles.mainContentWrap]
})<{ ownerState: FormOwnerState }>``;

export default Form;
